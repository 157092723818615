import { cdc } from '@onflow/fcl';

const CODE = cdc`import FungibleToken from 0xFungibleTokenContractAddress
import NonFungibleToken from 0xNonFungibleTokenContractAddress
import NFTStorefrontV2 from 0xNFTStorefrontContractAddress
import PackNFT, Golazos from 0xNFTContractAddress
import DapperUtilityCoin from 0xDapperUtilityCoinContractAddress
import MetadataViews from 0xMetadataViews

// This transaction purchases a pack from a dapp. This transaction will also initialize the buyer's account with a pack NFT
// collection and an NFT collection if it does not already have them.
// FIRST ARGUMENT OF THIS TRANSACTION MUST BE YOUR MERCHANT ACCOUNT ADDRESS PROVIDED BY DAPPER LABS
transaction(
    merchantAccountAddress: Address, 
    storefrontAddress: Address, 
    listingResourceID: UInt64, 
    expectedPrice: UFix64, 
    metadata: {String: String}
) {
    let paymentVault: @FungibleToken.Vault
    let buyerNFTCollection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    let balanceBeforeTransfer: UFix64
    let mainDUCVault: &DapperUtilityCoin.Vault
    let dappAddress: Address
    let salePrice: UFix64
    
    // "dapp" as authorizer is optional
    prepare(dapp: AuthAccount, dapper: AuthAccount, buyer: AuthAccount) {
        self.dappAddress = dapp.address

        let sportNftStorage = Golazos.CollectionStoragePath
        let sportNftPublicPath = Golazos.CollectionPublicPath

        // Initialize the Golazos collection if the buyer does not already have one
        if buyer.borrow<&Golazos.Collection>(from: sportNftStorage) == nil {
            // save it to the account
            buyer.save(<-Golazos.createEmptyCollection(), to: sportNftStorage)

            // create a public capability for the collection
            buyer.link<&Golazos.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>(
                sportNftPublicPath,
                target: sportNftStorage
            )
                ?? panic("Could not link Golazos.Collection Pub Path");
        }

        let packNftStorage = PackNFT.CollectionStoragePath
        let packNftPublicPath = PackNFT.CollectionPublicPath

        // Initialize the PackNFT collection if the buyer does not already have one
        if buyer.borrow<&PackNFT.Collection>(from: packNftStorage) == nil {
            buyer.save(<-PackNFT.createEmptyCollection(), to: packNftStorage);
            buyer.link<&{NonFungibleToken.CollectionPublic}>(packNftPublicPath, target: packNftStorage)
                ?? panic("Could not link PackNFT.Collection Pub Path");
        }

        self.storefront = dapp
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath)
            .borrow()
            ?? panic("Could not borrow a reference to the storefront")
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Listing with that ID in Storefront")

        self.salePrice = self.listing.getDetails().salePrice

        self.mainDUCVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
                    ?? panic("Could not borrow reference to Dapper Utility Coin vault")
        self.balanceBeforeTransfer = self.mainDUCVault.balance
        self.paymentVault <- self.mainDUCVault.withdraw(amount: self.salePrice)

        self.buyerNFTCollection = buyer
            .getCapability<&{NonFungibleToken.CollectionPublic}>(packNftPublicPath)
            .borrow()
            ?? panic("Cannot borrow NFT collection receiver from account")
    }

    pre {
        self.salePrice == expectedPrice: "unexpected price"
        self.dappAddress == 0xNFTContractAddress && self.dappAddress == storefrontAddress: "Requires valid authorizing signature"
    }

    execute {
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: nil
        )

        self.buyerNFTCollection.deposit(token: <-item)
    }

    post {
        self.mainDUCVault.balance == self.balanceBeforeTransfer: "DUC leakage"
    }
}`;

export { CODE };
