import { cdc } from '@onflow/fcl';

const CODE = cdc`import PackNFT from 0xPackNFTContractAddress
import IPackNFT from 0xIPackNFT

transaction(revealID: UInt64) {
    prepare(owner: AuthAccount) {
        let packNftStorage = PackNFT.CollectionStoragePath

        let collectionRef = owner.borrow<&PackNFT.Collection>(from: packNftStorage)!
        collectionRef.borrowPackNFT(id: revealID)!.reveal(openRequest: true)
    }
}`;

export { CODE };
